<template>
  <b-row>
    <b-col
      lg="12"
      md="12"
      class="chats border-right"
      :class="showChatId != null || isComposing ? 'd-none d-lg-block' : ''"
    >
      <div>
        <PageHeader :title="$t('general.chat.title')">
          <div class="messages-header-button__button-wrapper">
            <button
              type="button"
              @click="toggleSearch"
              class="messages-header-button__button"
            >
              <CIcon name="search" class="messages-header-button__icon" />
            </button>

            <CLink
              to="/messages/compose"
              class="messages-header-button__button"
            >
              <CIcon
                name="plus"
                class="messages-header-button__icon messages-header-button__icon--plus"
              />
            </CLink>
          </div>
        </PageHeader>

        <div class="mt-3" v-if="isSearch && massMessage.message">
          <CInput
            ref="input"
            type="text"
            name="searchedUser"
            input-mode="text"
            theme="secondary"
            v-model="searchedUser"
            :placeholder="$t('general.massMessage.search')"
          />
        </div>

        <b-row class="mt-3" v-if="isSend">
          <b-col>
            <SendMassMessageCard
              @unsend="onUnsend"
              @successSend="reloadChats"
            />
          </b-col>
        </b-row>

        <b-row v-if="chats.length">
          <InfinityScroll
            v-if="searchedList && searchedList.length"
            :items="searchedList"
            :has-more="Boolean(next_page_url)"
            @rich-end="loadMore"
            class="following__list"
          >
            <template #default="{ item: chat }">
              <MessageUser
                :key="chat.message.id"
                :chat="chat"
                class="following__user"
                @change="changeMessage"
              />
            </template>
          </InfinityScroll>
        </b-row>

        <b-row v-else>
          <b-col>
            <p
              class="text-center empty-message-text empty-message-text--strong"
            >
              {{ $t("general.chat.noMessages") }}
            </p>
            <p class="text-center empty-message-text">
              {{ $t("general.chat.noMessagesText") }}
            </p>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Message from "@/components/models/Message";
import User from "@/components/models/User";

import PageHeader from "@/layout/PageHeader.vue";
import CIcon from "@/features/ui/CIcon.vue";
import CLink from "@/features/ui/CLink.vue";
import MessageUser from "@/features/components/MessageUser.vue";
import SendMassMessageCard from "@/features/components/SendMassMessageCard.vue";
import CInput from "@/features/ui/СInput.vue";
import InfinityScroll from "@/features/components/InfinityScroll.vue";
import validationError from "@/mixins/validationError";
import { mapGetters } from "vuex";
export default {
  components: {
    InfinityScroll,
    CInput,
    SendMassMessageCard,
    MessageUser,
    CLink,
    CIcon,
    PageHeader,
  },
  mixins: [validationError],
  data: function () {
    return {
      chats: [],
      mass: null,
      showChatId: null,
      doCompose: false,
      isSend: false,

      isSearch: false,
      searchedUser: "",
      messages: {
        current_page: 1,
        next_page_url: null,
      },
      current_page: 1,
      next_page_url: null,
    };
  },
  computed: {
    ...mapGetters({
      massMessage: "messages/massMessage",
    }),
    chatId() {
      return this.$route.params.id && this.$route.params.id != "compose"
        ? this.$route.params.id * 1
        : null;
    },
    isComposing() {
      return this.$route.params.id == "compose";
    },
    pusher() {
      return this.$store.state.pusher;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    searchedList() {
      if (!this.searchedUser) return this.chats;

      return this.chats.filter((chat) =>
        chat.party.name.toLowerCase().includes(this.searchedUser.toLowerCase())
      );
    },
  },
  watch: {
    chatId(v) {
      this.showChatId = v;
    },
    pusher() {
      this.listen();
    },
  },
  mounted() {
    this.initState();
  },
  beforeDestroy() {
    this.unlisten();
  },
  methods: {
    initState() {
      this.isSend = this.$route.query?.send;
      this.loadChats();
      this.listen();
    },
    toggleSearch() {
      this.isSearch = !this.isSearch;
    },
    onUnsend() {
      this.isSend = false;
    },
    reloadChats() {
      this.isSend = false;
      this.unlisten();
      this.listen();
      this.loadChats();
    },

    listen() {
      if (this.pusher) {
        this.pusher.bind("message", this.handleNewMessage);
        this.pusher.bind("message.read", this.handleMessageRead);
        this.pusher.bind(
          "message.mass.complete",
          this.handleMassMessageComplete
        );
      }
    },
    unlisten() {
      if (this.pusher) {
        this.pusher.unbind("message", this.handleNewMessage);
        this.pusher.unbind("message.read", this.handleMessageRead);
        this.pusher.unbind(
          "message.mass.complete",
          this.handleMassMessageComplete
        );
      }
    },
    handleNewMessage(data) {
      const newMessage = new Message(data.message);
      if (newMessage.user.id == this.showChatId) {
        this.$refs.chat.reloadFirst();
      } else {
        let valid = [];
        let chats = [...this.chats];
        let found = false;
        for (let c of chats) {
          if (c.party.id == newMessage.user.id) {
            c.message = newMessage;
            found = true;
            valid.unshift(c);
          } else {
            valid.push(c);
          }
        }
        if (!found) {
          valid.unshift({
            party: newMessage.user,
            message: newMessage,
          });
        }

        this.chats = valid;
      }
    },
    handleMessageRead(data) {
      if (data.id == this.showChatId) {
        this.$refs.chat.readMessages();
      }
    },
    handleMassMessageComplete(data) {
      const m = new Message(data.message);
      m.recipients_count = data.message.recipients_count;
      m.mass_complete = true;
      this.mass = m;
    },
    isOwner(message) {
      return message.user.id == this.$store.state.currentUser.id;
    },

    loadChats() {
      if (this.chats.length == 0) {
        this.$showSpinner();
      }
      this.$get(
        "/messages",
        this.successLoad,
        (errors) => {
          console.log(errors);
        },
        this.checkErrors
      );
    },

    changeMessage(message) {
      this.chats = this.chats.filter(
        (chat) => chat.party?.id !== message?.party?.id
      );
    },
    loadMore() {
      this.$get(
        `/messages?page=${this.current_page + 1}`,
        this.successLoad,
        (errors) => {
          console.log(errors);
        },
        this.checkErrors
      );
    },

    successLoad(data) {
      this.current_page = data.chats?.current_page;
      this.next_page_url = data.chats?.next_page_url || "";

      if (this.current_page === 1) {
        this.chats = this.createChats(data?.chats?.data || []);
      } else {
        this.chats = [
          ...(this.chats || []),
          ...this.createChats(data?.chats?.data || []),
        ];
      }

      if (data.mass) {
        this.mass = new Message(data.mass);
        this.mass.recipients_count = data.mass.recipients_count;
      }
      this.$nextTick(function () {
        this.showChatId = this.chatId;
      });
    },

    createChats(chats) {
      return chats.map((chat) => {
        return {
          party: new User(chat.party),
          message: new Message(chat),
        };
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/vendors/_variables.scss";
.list {
  cursor: pointer;
}
.mass-alert {
  background-color: lighten($primary, 50%);
}

.following {
  &__user {
    width: 100%;
    padding: em(15) em(15);
  }
  &__list {
    flex: 1;
  }
}
</style>
<style lang="scss">
@import "~@/assets/scss/vendors/_variables.scss";
@include media-breakpoint-down(md) {
  .chats {
    border-right-width: 0px !important;
  }
}
@include media-breakpoint-up(lg) {
  .chats {
    border-right-width: 1px !important;
  }
}
.chats {
  min-height: calc(var(--vh, 1vh) * 100);
  .item {
    padding-left: 4rem;
    min-height: 4.5rem;
    align-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: var(--light);
    }
    .b-avatar {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
    .x {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
    .link {
      display: block;
      color: inherit;
      padding: 0 1rem 0 0;
      flex: 1;
      width: calc(100% - 80px);
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
    .body {
      white-space: nowrap;
      .message {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .chats {
    min-height: calc(var(--vh, 1vh) * 100 - (#{$spacer} * 4));
  }
}

.messages-header-button {
  &__button-wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  &__button {
    //&:first-child {
    //  margin-right: em(24);
    //}
    width: em(30);
    text-align: center;
  }

  &__icon {
    color: $app-gray-17;
    width: em(19);
    height: em(19);

    &--plus {
      width: em(14);
      height: em(14);
    }
  }
}

.empty-message-text {
  color: $app-font-color-gray-7;
  text-align: center;
  font-family: $font-default;
  font-size: em(14);
  font-style: normal;
  font-weight: 400;
  line-height: em(18);
  padding-bottom: em(11);
  margin-bottom: 0;

  &--strong {
    margin-top: em(27);
    color: black;
    font-size: em(18);
    font-weight: 500;
  }
}

.base-button {
  $parent: &;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 24) em(15, 24);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
</style>
