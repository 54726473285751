<template>
  <div class="send-mass-message-card">
    <div class="send-mass-message-card__text">
      <div class="send-mass-message-card__title">
        <span
          class="send-mass-message-card__font send-mass-message-card__font--title"
        >
          {{
            $t("general.massMessage.message", { users: userSendingAmount || 0 })
          }}
        </span>
      </div>

      <div class="send-mass-message-card__progress" v-if="counter > 0">
        <CIcon
          name="loader"
          path="statements"
          class="send-mass-message-card__icon send-mass-message-card__icon--loader"
        />

        <span
          class="send-mass-message-card__font send-mass-message-card__font--progress"
        >
          {{ $t("general.massMessage.inProgress") }}
        </span>
      </div>

      <div class="send-mass-message-card__progress" v-else>
        <CIcon
          name="check"
          path="statements"
          class="send-mass-message-card__icon send-mass-message-card__icon--loader"
        />

        <span
          class="send-mass-message-card__font send-mass-message-card__font--progress"
        >
          {{ $t("general.massMessage.send") }}
        </span>
      </div>
    </div>

    <button
      v-if="counter > 0"
      @click="unsend"
      class="button send-mass-message-card__button"
    >
      <span
        class="send-mass-message-card__font send-mass-message-card__font--button"
      >
        {{ $t("general.massMessage.unsend", { counter }) }}
      </span>
    </button>
  </div>
</template>
<script>
import CIcon from "@/features/ui/CIcon.vue";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import { mapActions, mapGetters } from "vuex";
import { FormStorageService } from "@/services/FormStorageService";
import { massMessage } from "@/config/messages";

export default {
  name: "SendMassMessageCard",
  components: { CIcon },
  mixins: [waitRequest, validationError],
  computed: {
    ...mapGetters({
      userSendingAmount: "messages/userSendingAmount",
      massMessage: "messages/massMessage",
    }),
  },
  data() {
    return {
      timer: null,
      intervalId: null,
      counter: 7,
    };
  },
  mounted() {
    this.send();
  },
  methods: {
    ...mapActions({
      createMassMessage: "messages/createMassMessage",
    }),
    unsend() {
      clearTimeout(this.timer);
      FormStorageService.clearForm(massMessage.form);
      FormStorageService.clearForm(massMessage.users);
      this.$emit("unsend");
    },

    tick() {
      this.counter -= 1;
      if (this.counter < 0) {
        this.pushMessage();
        clearTimeout(this.timer);
      } else {
        this.timer = setTimeout(this.tick, 1000);
      }
    },
    send() {
      this.timer = setTimeout(this.tick, 1000);
    },

    pushMessage() {
      this.waitRequest(() => {
        this.$router.replace({ query: null });
        return this.createMassMessage({ ...this.massMessage })
          .then(() => {
            this.$emit("successSend");
          })
          .catch(this.checkErrors);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.send-mass-message-card {
  display: flex;
  align-items: center;
  border-radius: 6px;
  background: $app-gray-36;
  padding: 9px 11px 10px 13px;
  margin-bottom: 10px;

  &__text {
    flex: 1;
  }

  &__title {
  }

  &__font {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;

    &--title {
      color: $black;
    }

    &--progress {
      font-weight: 500;
      font-size: 10px;
      color: $app-gray-37;
    }

    &--button {
      white-space: nowrap;
      font-weight: 700;
      color: $app-blue;
    }
  }

  &__icon {
    &--loader {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      margin-left: 3px;

      ::v-deep .dot {
        fill: black;
        animation: pulse 1s infinite;
      }

      @keyframes pulse {
        0%,
        100% {
          r: 3;
        }
        50% {
          r: 8;
        }
      }
    }
  }

  &__progress {
    margin-top: 4px;
  }

  &__button {
    flex: 0;
  }
}
</style>
