<template>
  <div class="message-user">
    <div class="message-user__avatar">
      <b-avatar
        :text="user.initials"
        :src="user.avatar"
        :to="user.url"
        size="44px"
        class=""
      />
    </div>

    <CLink :to="toChat" class="message-user__main">
      <div class="message-user__user">
        <span class="message-user__font message-user__font--user">
          {{ user.name }}
        </span>

        <span class="message-user__font message-user__font--username">
          @{{ user.username }}
        </span>
      </div>

      <div class="message-user__username">
        <span class="message-user__font message-user__font--message">
          <CIcon v-if="!isRead" name="arrow" path="messages" class="message-user__icon message-user__icon--arrow"/>
          {{ message }}
        </span>
      </div>
    </CLink>

    <div class="message-user__addition">
      <div class="message-user__time">
        <span class="message-user__font message-user__font--created-at">
          {{ createdAt }}
        </span>
      </div>

      <button type="button" @click.prevent="cleanChat" class="message-button">
        <CIcon name="close" class="message-user__icon" />
      </button>
    </div>
  </div>
</template>

<script>
import CLink from "@/features/ui/CLink.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import CIcon from "@/features/ui/CIcon.vue";
import dayjs from "dayjs";

export default {
  name: "MessageUser",
  components: { CIcon, CLink },
  mixins: [waitRequest, validationError],
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },
  computed: {
    user() {
      if (this.isAuthor) {
        return this.chat?.party
      }

      return this.chat?.message?.user;
    },

    isRead() {
      return this.chat?.message?.isRead
    },

    message() {
      return this.chat?.message?.message;
    },

    isAuthor() {
      const userId = this.chat?.message?.user?.id;
      const activeUserId = this.$store.state.currentUser.id;

      return userId === activeUserId;
    },

    createdAt() {
      return dayjs(this.chat?.message?.created_at).fromNow();
    },

    toChat() {
      return "/messages/" + this.chat?.party?.id;
    },
  },
  methods: {
    cleanChat() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.$post(
          "/messages/" + this.chat?.party?.id,
          {
            _method: "DELETE",
          },
          () => {
            this.$emit("change", this.chat);
          },
          (errors) => {
            console.log(errors);
          },
          this.checkErrors
        );
      }).catch(this.checkErrors);
    },
  },
};
</script>

<style scoped lang="scss">
.message-button {
  $parent: &;
  border-radius: em(100, 16);
  padding: em(7, 16) em(15, 16);

  &__font {
    color: $app-blue;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.message-user {
  display: flex;
  //flex-wrap: wrap;
  padding: em(10) em(12);
  width: 100%;
  pointer-events: auto;

  &__avatar {
    margin-right: em(11);
  }

  &__main {
    flex-grow: 1;
  }

  &__user {
    justify-content: flex-start;
  }

  &__username {
    margin-top: em(3);
  }

  &__addition {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__time {
    display: inline-flex;
  }

  &__font {
    color: $app-black;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &--user {
      color: $app-black;
      font-size: em(14);
      font-weight: 500;
    }

    &--username {
      color: $app-font-color-gray-6;
      font-size: em(12);
    }

    &--message {
      color: $app-font-color-gray-6;
      font-size: em(12);
      position: relative;
    }

    &--created-at {
      color: $app-font-color-gray-6;
      font-size: em(10);
      white-space: nowrap;
    }
  }

  &__icon {
    width: em(14);
    height: em(14);
    color: $app-gray-18;

    &--arrow {
      width: 10px;
      height: 9px;
      color: $black;
    }
  }
}
</style>
